import { Injectable } from '@angular/core';
import { AppConstants } from '../constants/app-constant';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // login
  private _msalInfo: any;
  private _accessToken: any;
  private _redirectUrl: any;
  private _currency: any;

  // legal-entity
  private _userLegalEntities: any;
  private _defaultLegalEntity: any;

  // user-roles
  private _userRoles: any;

  // my profile
  private _dateFormat: any;
  private _selectedLanguage: any;

  // plp
  private _query: any;

  // cart
  private _cartId: any;
  private _carts: any;
  private _activeCart: any;

  // checkout
  private _paramsCartGroupId: any;
  private _checkoutCartGroupId: any;
  private _cartCode: any;
  private _secondaryCartCode: any;

  // user-management
  private _userProfileData: any;


  constructor() { }

  // login
  public get msalInfo() {
    if (!this._msalInfo) {
      this._msalInfo = localStorage.getItem(AppConstants.LocalStorageKeys.msalInfo)
        ? JSON.parse(localStorage.getItem(AppConstants.LocalStorageKeys.msalInfo))
        : null;
    }
    return this._msalInfo;
  }
  public set msalInfo(v) {
    this._msalInfo = v;
    localStorage.setItem(AppConstants.LocalStorageKeys.msalInfo, JSON.stringify(v));
  }

  public get accessToken() {
    if (!this._accessToken) {
      this._accessToken = localStorage.getItem(AppConstants.LocalStorageKeys.auth)
        ? JSON.parse(localStorage.getItem(AppConstants.LocalStorageKeys.auth))
        : null;
    }
    return this._accessToken;
  }
  public set accessToken(v) {
    this._accessToken = v;
    localStorage.setItem(AppConstants.LocalStorageKeys.auth, JSON.stringify(v));
  }

  public get redirectUrl() {
    if (!this._currency) {
      this._redirectUrl = localStorage.getItem(AppConstants.LocalStorageKeys.redirectUrl)
        ? JSON.parse(localStorage.getItem(AppConstants.LocalStorageKeys.redirectUrl))
        : null;
    }
    return this._redirectUrl;
  }
  public set redirectUrl(v) {
    this._redirectUrl = v;
    localStorage.setItem(AppConstants.LocalStorageKeys.redirectUrl, JSON.stringify(v));
  }

  public get currency() {
    if (!this._currency) {
      this._currency = localStorage.getItem(AppConstants.LocalStorageKeys.currency)
        ? JSON.parse(localStorage.getItem(AppConstants.LocalStorageKeys.currency))
        : null;
    }
    return this._currency;
  }
  public set currency(v) {
    this._currency = v;
    localStorage.setItem(AppConstants.LocalStorageKeys.currency, JSON.stringify(v));
  }


  // legal-entity
  public get userLegalEntities() {
    if (!this._userLegalEntities) {
      this._userLegalEntities = localStorage.getItem(AppConstants.SessionStorageKeys.userLegalEntities)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.userLegalEntities))
        : null;
    }
    return this._userLegalEntities;
  }
  public set userLegalEntities(v) {
    this._userLegalEntities = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.userLegalEntities, JSON.stringify(v));
  }

  public get defaultLegalEntity() {
    if (!this._defaultLegalEntity) {
      this._defaultLegalEntity = localStorage.getItem(AppConstants.SessionStorageKeys.defaultLegalEntity) !== 'undefined'
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.defaultLegalEntity))
        : null;
    }
    return this._defaultLegalEntity;
  }
  public set defaultLegalEntity(v) {
    this._defaultLegalEntity = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.defaultLegalEntity, JSON.stringify(v));
  }

  public get userRoles() {
    if (!this._userRoles) {
      this._userRoles = localStorage.getItem(AppConstants.SessionStorageKeys.userRoles) !== 'undefined'
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.userRoles))
        : null;
    }
    return this._userRoles;
  }
  public set userRoles(v) {
    this._userRoles = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.userRoles, JSON.stringify(v));
  }


  // my profile
  public get dateFormat() {
    if (!this._dateFormat) {
      this._dateFormat = localStorage.getItem(AppConstants.SessionStorageKeys.dateFormat)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.dateFormat))
        : null;
    }
    return this._dateFormat;
  }
  public set dateFormat(v) {
    this._dateFormat = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.dateFormat, JSON.stringify(v));
  }

  public get selectedLanguage() {
    if (!this._selectedLanguage) {
      this._selectedLanguage = localStorage.getItem(AppConstants.SessionStorageKeys.selectedLanguage)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.selectedLanguage))
        : null;
    }
    return this._selectedLanguage;
  }
  public set selectedLanguage(v) {
    this._selectedLanguage = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.selectedLanguage, JSON.stringify(v));
  }


  // plp
  public get query() {
    if (!this._query) {
      this._query = localStorage.getItem(AppConstants.SessionStorageKeys.query)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.query))
        : null;
    }
    return this._query;
  }
  public set query(v) {
    this._query = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.query, JSON.stringify(v));
  }


  // cart
  public get cartId() {
    if (!this._cartId) {
      this._cartId = localStorage.getItem(AppConstants.SessionStorageKeys.cartId) !== 'undefined'
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.cartId))
        : null;
    }
    return this._cartId;
  }
  public set cartId(v) {
    this._cartId = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.cartId, JSON.stringify(v));
  }

  public get carts() {
    if (!this._carts) {
      this._carts = localStorage.getItem(AppConstants.SessionStorageKeys.carts) !== 'undefined'
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.carts))
        : null;
    }
    return this._carts;
  }
  public set carts(v) {
    this._carts = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.carts, JSON.stringify(v));
  }

  public get activeCart() {
    if (!this._activeCart) {
      this._activeCart = localStorage.getItem(AppConstants.SessionStorageKeys.activeCart) !== 'undefined'
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.activeCart))
        : null;
    }
    return this._activeCart;
  }
  public set activeCart(v) {
    this._activeCart = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.activeCart, JSON.stringify(v));
  }

  // checkout
  public get paramsCartGroupId() {
    if (!this._paramsCartGroupId) {
      this._paramsCartGroupId = localStorage.getItem(AppConstants.SessionStorageKeys.paramsCartGroupId)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.paramsCartGroupId))
        : null;
    }
    return this._paramsCartGroupId;
  }
  public set paramsCartGroupId(v: string) {
    this._paramsCartGroupId = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.paramsCartGroupId, JSON.stringify(v));
  }

  public get checkoutCartGroupId() {
    if (!this._checkoutCartGroupId) {
      this._checkoutCartGroupId = localStorage.getItem(AppConstants.SessionStorageKeys.checkoutCartGroupId)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.checkoutCartGroupId))
        : null;
    }
    return this._checkoutCartGroupId;
  }
  public set checkoutCartGroupId(v: string) {
    this._checkoutCartGroupId = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.checkoutCartGroupId, JSON.stringify(v));
  }

  public get cartCode() {
    if (!this._cartCode) {
      this._cartCode = localStorage.getItem(AppConstants.SessionStorageKeys.cartCode)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.cartCode))
        : null;
    }
    return this._cartCode;
  }
  public set cartCode(v: string) {
    this._cartCode = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.cartCode, JSON.stringify(v));
  }

  public get secondaryCartCode() {
    if (!this._secondaryCartCode) {
      this._secondaryCartCode = localStorage.getItem(AppConstants.SessionStorageKeys.secondaryCartCode)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.secondaryCartCode))
        : null;
    }
    return this._secondaryCartCode;
  }
  public set secondaryCartCode(v) {
    this._secondaryCartCode = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.secondaryCartCode, JSON.stringify(v));
  }


  // user-management
  public get userProfileData() {
    if (!this._userProfileData) {
      this._userProfileData = localStorage.getItem(AppConstants.SessionStorageKeys.userProfileData)
        ? JSON.parse(localStorage.getItem(AppConstants.SessionStorageKeys.userProfileData))
        : null;
    }
    return this._userProfileData;
  }
  public set userProfileData(v) {
    this._userProfileData = v;
    localStorage.setItem(AppConstants.SessionStorageKeys.userProfileData, JSON.stringify(v));
  }



  reset() {
    this.localStorageReset();
    this.sessionStorageReset();

    localStorage.clear();
    sessionStorage.clear();
  }

  localStorageReset() {
    // login
    this._msalInfo = null;
    this._accessToken = null;
    this._redirectUrl = null;
    this._currency = null;
    this._userLegalEntities = null;
    this._defaultLegalEntity = null;
    this._userRoles = null;
    this._cartId = null;
    this._carts = null;
    this._activeCart = null;
    this._query = null;
    this._userProfileData = null;
    this._dateFormat = null;
    this._selectedLanguage = null;
    this._cartCode = null;
    this._secondaryCartCode = null;
    this._paramsCartGroupId = null;
    this._checkoutCartGroupId = null;

    localStorage.removeItem(AppConstants.LocalStorageKeys.msalInfo);
    localStorage.removeItem(AppConstants.LocalStorageKeys.auth);
    localStorage.removeItem(AppConstants.LocalStorageKeys.redirectUrl);
    localStorage.removeItem(AppConstants.LocalStorageKeys.currency);
    localStorage.removeItem(AppConstants.SessionStorageKeys.userLegalEntities);
    localStorage.removeItem(AppConstants.SessionStorageKeys.defaultLegalEntity);
    localStorage.removeItem(AppConstants.SessionStorageKeys.userRoles);
    localStorage.removeItem(AppConstants.SessionStorageKeys.dateFormat);
    localStorage.removeItem(AppConstants.SessionStorageKeys.selectedLanguage);
    localStorage.removeItem(AppConstants.SessionStorageKeys.query);
    localStorage.removeItem(AppConstants.SessionStorageKeys.cartId);
    localStorage.removeItem(AppConstants.SessionStorageKeys.carts);
    localStorage.removeItem(AppConstants.SessionStorageKeys.activeCart);
    localStorage.removeItem(AppConstants.SessionStorageKeys.paramsCartGroupId);
    localStorage.removeItem(AppConstants.SessionStorageKeys.checkoutCartGroupId);
    localStorage.removeItem(AppConstants.SessionStorageKeys.cartCode);
    localStorage.removeItem(AppConstants.SessionStorageKeys.secondaryCartCode);
    localStorage.removeItem(AppConstants.SessionStorageKeys.userProfileData);
  }

  sessionStorageReset() {
  }

  resetSessionDetails() {
    localStorage.removeItem(AppConstants.LocalStorageKeys.anonymous);
    localStorage.removeItem(AppConstants.LocalStorageKeys.auth);
    localStorage.removeItem(AppConstants.LocalStorageKeys.cart);
    localStorage.removeItem(AppConstants.LocalStorageKeys.currency);
    localStorage.removeItem(AppConstants.LocalStorageKeys.language);
    localStorage.removeItem(AppConstants.LocalStorageKeys.msalInfo);
  }
}
